.navigation-links3-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navigation-links3-link {
  text-decoration: none;
}
.navigation-links3-link1 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navigation-links3-link2 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}








@media(max-width: 767px) {
  .navigation-links3-nav {
    align-items: flex-start;
    flex-direction: column;
  }
  .navigation-links3-link {
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links3-link1 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links3-link2 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
}
