.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-header {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-nav {
  display: flex;
  justify-content: flex-start;
}
.home-burger-menu {
  display: none;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  display: none;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.home-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-image {
  height: 2rem;
}
.home-close-mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-about-me {
  width: 100%;
  height: fit-content;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: 72vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-summary {
  display: flex;
  margin-right: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-text {
  font-size: 3rem;
  max-width: 450px;
}
.home-text01 {
  font-size: 24px;
  margin-top: var(--dl-space-space-unit);
  font-family: Noto Sans;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text04 {
  font-size: 20px;
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-unit);
  text-align: left;
  font-family: Noto Sans;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-unit);
}
.home-social-bar {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
.home-link {
  display: contents;
}
.home-icon10 {
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-link01 {
  display: contents;
}
.home-icon12 {
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-link02 {
  display: contents;
}
.home-icon14 {
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-link03 {
  display: contents;
}
.home-icon16 {
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-link04 {
  display: contents;
}
.home-icon18 {
  width: 30px;
  height: 30px;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-image01 {
  width: 400px;
  object-fit: cover;
}
.home-research {
  width: 100%;
  height: fit-content;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  min-height: 0px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading {
  flex: 0 0 auto;
  width: 100%;
  height: 72px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.home-text06 {
  font-size: 20px;
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-unit);
  text-align: left;
  font-family: Noto Sans;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-unit);
}
.home-link05 {
  text-decoration: underline;
}
.home-link06 {
  text-decoration: underline;
}
.home-link07 {
  text-decoration: underline;
}
.home-link08 {
  text-decoration: underline;
}
.home-link09 {
  text-decoration: underline;
}
.home-link10 {
  text-decoration: underline;
}
.home-separator {
  width: 60%;
  height: 1px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-gray-900);
}
.home-research1 {
  width: 100%;
  height: fit-content;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  min-height: 0px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading1 {
  flex: 0 0 auto;
  width: 100%;
  height: 72px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.home-container02 {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-text25 {
  font-style: italic;
}
.home-paper {
  width: 100%;
  height: max-content;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.home-container03 {
  flex: 0 0 auto;
  width: 20%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-image02 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-container04 {
  flex: 0 0 auto;
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
}
.home-container05 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text27 {
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-text29 {
  font-weight: 700;
}
.home-link11 {
  display: contents;
}
.home-text31 {
  padding-top: 0px;
  padding-bottom: 0px;
  text-decoration: underline;
}
.home-paper1 {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.home-container06 {
  flex: 0 0 auto;
  width: 20%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-image03 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-container07 {
  flex: 0 0 auto;
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
}
.home-container08 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text33 {
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-text34 {
  font-weight: 700;
}
.home-link12 {
  display: contents;
}
.home-text36 {
  padding-top: 0px;
  padding-bottom: 0px;
  text-decoration: underline;
}
.home-paper2 {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.home-container09 {
  flex: 0 0 auto;
  width: 20%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-image04 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-container10 {
  flex: 0 0 auto;
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
}
.home-container11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text38 {
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-text39 {
  font-weight: 700;
}
.home-link13 {
  display: contents;
}
.home-text41 {
  padding-top: 0px;
  padding-bottom: 0px;
  text-decoration: underline;
}
.home-paper3 {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.home-container12 {
  flex: 0 0 auto;
  width: 20%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-image05 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-container13 {
  flex: 0 0 auto;
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
}
.home-container14 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text43 {
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-text45 {
  font-weight: 700;
}
.home-link14 {
  display: contents;
}
.home-text47 {
  padding-top: 0px;
  padding-bottom: 0px;
  text-decoration: underline;
}
.home-container15 {
  width: 100%;
  height: max-content;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-text48 {
  font-style: italic;
}
.home-paper4 {
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.home-container16 {
  flex: 0 0 auto;
  width: 20%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-image06 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-container17 {
  flex: 0 0 auto;
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
}
.home-container18 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text50 {
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-text52 {
  font-weight: 700;
}
.home-text53 {
  font-weight: 700;
}
.home-link15 {
  display: contents;
}
.home-text55 {
  padding-top: 0px;
  padding-bottom: 0px;
  text-decoration: underline;
}
.home-paper5 {
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.home-container19 {
  flex: 0 0 auto;
  width: 20%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-image07 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-container20 {
  flex: 0 0 auto;
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
}
.home-container21 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text57 {
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-text59 {
  font-weight: 700;
}
.home-link16 {
  display: contents;
}
.home-text61 {
  padding-top: 0px;
  padding-bottom: 0px;
  text-decoration: underline;
}
.home-paper6 {
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.home-container22 {
  flex: 0 0 auto;
  width: 20%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-image08 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-container23 {
  flex: 0 0 auto;
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
}
.home-container24 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text63 {
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-text65 {
  font-weight: 700;
}
.home-link17 {
  display: contents;
}
.home-text68 {
  padding-top: 0px;
  padding-bottom: 0px;
  text-decoration: underline;
}
.home-separator1 {
  width: 60%;
  height: 1px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-gray-900);
}
.home-research2 {
  width: 100%;
  height: fit-content;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  min-height: 0px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading2 {
  flex: 0 0 auto;
  width: 100%;
  height: 72px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.home-text70 {
  font-style: italic;
}
.home-container25 {
  flex: 0 0 auto;
  width: auto;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-container26 {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.home-feature-card {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.home-image09 {
  width: 90%;
  object-fit: cover;
  padding-left: 0px;
  padding-right: 0px;
}
.home-text71 {
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text72 {
  color: var(--dl-color-gray-black);
  text-align: center;
}
.home-ul {
  list-style-type: square;
}
.home-link18 {
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-unit);
  font-weight: 500;
  text-decoration: none;
}
.home-feature-card1 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.home-image10 {
  width: 90%;
  object-fit: cover;
}
.home-text76 {
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text77 {
  color: var(--dl-color-gray-black);
  text-align: center;
}
.home-ul1 {
  list-style-type: square;
}
.home-link19 {
  text-decoration: underline;
}
.home-link20 {
  text-decoration: underline;
}
.home-link21 {
  text-decoration: underline;
}
.home-link22 {
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-unit);
  font-weight: 500;
  text-decoration: none;
}
.home-text85 {
  font-style: italic;
}
.home-container27 {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.home-feature-card2 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.home-image11 {
  width: 60%;
  object-fit: cover;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-text86 {
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text87 {
  color: var(--dl-color-gray-700);
  text-align: center;
}
.home-link23 {
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-unit);
  font-weight: 500;
  text-decoration: none;
}
.home-feature-card3 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  padding-bottom: 32px;
}
.home-image12 {
  width: 60%;
  object-fit: cover;
  padding-left: 0px;
  padding-right: 0px;
}
.home-text88 {
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text89 {
  color: var(--dl-color-gray-700);
  text-align: center;
}
.home-link24 {
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-unit);
  font-weight: 500;
  text-decoration: none;
}
.home-separator2 {
  width: 60%;
  height: 1px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-gray-900);
}
.home-gallery {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.home-container28 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.home-footer {
  width: 100%;
  height: 158px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: 150px;
  padding-right: 150px;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-text90 {
  color: var(--dl-color-gray-500);
}
@media(max-width: 991px) {
  .home-icon {
    display: flex;
  }
  .home-about-me {
    height: 1185px;
    flex-direction: column;
  }
  .home-summary {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-text {
    text-align: center;
  }
  .home-text01 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-text04 {
    max-width: 100%;
  }
  .home-research {
    flex-direction: column;
  }
  .home-text06 {
    max-width: 100%;
  }
  .home-text07 {
    max-width: 100%;
  }
  .home-research1 {
    flex-direction: column;
  }
  .home-research2 {
    flex-direction: column;
  }
  .home-container28 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media(max-width: 767px) {
  .home-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-nav {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-about-me {
    height: 1514px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text01 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text04 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-social-bar {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-image01 {
    width: 80%;
  }
  .home-research {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text06 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text07 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-research1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-separator1 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-research2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-separator2 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-gallery {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container28 {
    grid-template-columns: 1fr 1fr;
  }
  .home-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text90 {
    text-align: center;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .home-header {
    padding: var(--dl-space-space-unit);
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-about-me {
    height: 1442px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-summary {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-social-bar {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-research {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-separator {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-research1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container03 {
    height: 100%;
    display: none;
  }
  .home-container04 {
    height: 100%;
  }
  .home-container06 {
    display: none;
  }
  .home-container09 {
    display: none;
  }
  .home-container12 {
    display: none;
  }
  .home-container16 {
    display: none;
  }
  .home-container19 {
    display: none;
  }
  .home-container22 {
    display: none;
  }
  .home-separator1 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-research2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-separator2 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-gallery {
    padding: var(--dl-space-space-unit);
  }
  .home-container28 {
    grid-template-columns: 1fr;
  }
  .home-footer {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .home-text90 {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
}
